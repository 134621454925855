import React from "react"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"

export default function Refund() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Refund Policy | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Refund Policy
            </h2>
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                    <p className="text-2xl font-bold text-gray-900 ">
                    14 days free on a new pay account.
                    </p>

                    <p className="mt-8 text-lg font-normal text-gray-600 ">
                    Skillmeter offers a free 14-day trial on new pay accounts (Standard, Premium, Ultimate).
                    </p>
                </div>
                <div className="py-8">
                    <p className="text-2xl font-bold text-gray-900 ">
                    Cancel within the first 14 days and you won’t be charged.
                    </p>

                    <p className="mt-8 text-lg font-normal text-gray-600 ">
                    If you cancel your paid account before you are billed 14 days after your original signup then you won’t be charged.
                    </p>
                </div>
                <div className="py-8">
                    <p className="text-2xl font-bold text-gray-900 ">
                    Cancel after 14 days and you won’t be charged again.
                    </p>

                    <p className="mt-8 text-lg font-normal text-gray-600 ">
                    Once you cancel you won’t be charged again, but you are responsible for whatever charges have already been incurred for the current billing period. For example, if your billing cycle is on the 18th of every month, and you cancel on the 24th, you’ll still have to pay for the current month, but you won’t be charged again after that. In order to treat everyone equally, no exceptions will be made.
                    </p>
                </div>
                <div className="py-8">
                    <p className="text-2xl font-bold text-gray-900 ">
                    How do I cancel my account?
                    </p>

                    <p className="mt-8 text-lg font-normal text-gray-600 ">
                    You can cancel your account at any time by logging into your account and going to "My Account" section. From there, click the "Delete my Skillmeter account” link.
                    </p>

                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}